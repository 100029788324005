import React from 'react';

import { useTheme } from '../../context/ThemeContext';

interface PythonLogoProps {}

export const PythonLogo = ({ ...props }: PythonLogoProps) => {
  const { activeTheme } = useTheme();
  return (
    <>
      <div className="logo-container">
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49.5765 0.223633C24.2514 0.223635 25.8329 11.2061 25.8329 11.2061L25.8611 22.5839H50.0282V26H16.262C16.262 26 0.0564575 24.1622 0.0564575 49.7154C0.0564557 75.2687 14.201 74.3625 14.201 74.3625H22.6426V62.5048C22.6426 62.5048 22.1875 48.3603 36.5613 48.3603H60.5308C60.5308 48.3603 73.9977 48.578 73.9977 35.345V13.4647C73.9977 13.4647 76.0424 0.223633 49.5765 0.223633ZM36.2507 7.87468C38.6552 7.87467 40.5985 9.81805 40.5985 12.2225C40.5985 14.627 38.6552 16.5703 36.2507 16.5703C33.8462 16.5703 31.9029 14.627 31.9029 12.2225C31.9029 9.81805 33.8462 7.87468 36.2507 7.87468Z"
            fill={activeTheme === 'light' ? '#2F2F3A' : '#ffffff'}
          />
          <path
            d="M50.2957 99.4634C75.6208 99.4634 74.0394 88.4809 74.0394 88.4809L74.0112 77.1032H49.844V73.687H83.6102C83.6102 73.687 99.8158 75.5249 99.8158 49.9716C99.8158 24.4184 85.6712 25.3245 85.6712 25.3245H77.2297V37.1822C77.2297 37.1822 77.6847 51.3268 63.311 51.3268H39.3415C39.3415 51.3268 25.8745 51.1091 25.8745 64.342V86.2223C25.8745 86.2223 23.8298 99.4634 50.2957 99.4634ZM63.6215 91.8124C61.2171 91.8124 59.2737 89.869 59.2737 87.4646C59.2737 85.0601 61.2171 83.1167 63.6215 83.1167C66.026 83.1167 67.9694 85.0601 67.9694 87.4646C67.9694 89.869 66.026 91.8124 63.6215 91.8124Z"
            fill={activeTheme === 'light' ? '#2F2F3A' : '#ffffff'}
          />
        </svg>

        <p style={{ color: activeTheme === 'light' ? '#2F2F3A' : '#ffffff' }}>
          Python
        </p>
      </div>
    </>
  );
};

export default PythonLogo;
